.side-nav {
  background-color: #03a9f4;
}
.side-nav li > a {
  color: white !important;
}

.brand-logo {
  padding-left: 1rem !important;
}

#home-container {
  background: url('./project-images/background.jpg');
  background-attachment: fixed;
  background-size: cover;
  color: white;
  height: 500px;
}

/* #home-container:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(68, 68, 68, 0.8);
  z-index: 0;
} */

#main-container-content {
  background-color: rgba(2, 136, 209, 0.6);
  margin-top: 4rem;
  border-radius: 1rem;
}

.cvBtn {
  margin-left: 1rem;
}
